<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="LBLBASEINFO">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                label="출력"
                icon="print"
                @btnClicked="printInfo" />
              <c-appr-btn 
                ref="appr-btn"
                name="appr-btn"
                :editable="editable"
                :approvalInfo="approvalInfo"
                @beforeApprAction="approvalHealtyTargetMonth"
                @callbackApprAction="approvalHealtyTargetMonthCallback"
                @requestAfterAction="getDetail"
              />
              <!--저장-->
              <c-btn 
                v-show="editable&&!disabled" 
                :isSubmit="isSave"
                :url="updateUrl"
                :param="month"
                mappingType="PUT"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveTargetMonth"
                @btnCallback="saveTargetMonthCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--대상년도-->
              <c-datepicker
                :editable="editable"
                :disabled="true"
                type="month"
                label="LBL0010014"
                name="yearmonth"
                v-model="month.yearmonth"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-field
                :editable="editable"
                :disabled="true"
                :data="month"
                deptValue="manageSupervisorDeptCd"
                type="dept_user"
                label="관리감독자"
                name="manageSupervisorId"
                v-model="popupParam.manageSupervisorId"
              />
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <!-- :data="month.depts.filter(i => i.deptCd === tab.deptCd).results" -->
        <c-table
          ref="table"
          :title="`${popupParam.month}월 ${tab.label} 부서 실적 현황`"
          :merge="grid.merge"
          :columns="tab.deptCd !== 'ETC' ? grid.columns : grid.etcColumns"
          :data="tab.results"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled&&tab.completeFlag!=='Y'"
          :usePaging="false"
          :isExcelDown="true"
          :filtering="false"
          :isFullScreen="true"
          :columnSetting="false"
        >
          <template slot="suffixTitle">
            <font v-if="tab.deptCd === 'ETC'" style="font-size:0.9em;font-weight:600;color:red;">
              (※ 팀이 기타인 경우 EHS에서 자동으로 데이터를 들고 올 수 없음으로 직접 입력하시기 바랍니다.)
            </font>
          </template>
        </c-table>
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safetyHealthyTargetMonth',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiSafetyHealthyTargetId: '',
        saiSafetyHealthyTargetMonthId: '',
        targetYear: '',
        month: 0,
        manageSupervisorId: '',
        holidaies: [],
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      month: {
        saiSafetyHealthyTargetMonthId: '',  // 안전보건목표 일련번호
        saiSafetyHealthyTargetId: '',  // 안전보건목표 일련번호
        yearmonth: '',  // 년월
        saiSafetyHealthyTargetMonthStepCd: '',  // 진행상태
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        depts: [],
      },
      tab: '',
      grid: {
        merge: [{ index: 0, colName: "targetName" }],
        columns: [],
        etcColumns: [],
        data: [],
      },
      editable: false,
      isSave: false,
      isApproval: false,
      detailUrl: '',
      printUrl: '',
      updateUrl: '',
      approvalUrl: '',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let height = (this.contentHeight - 130);
      if (height < 650) {
        height = 650;
      }
      return String(height) + 'px';
    },
    gridHeight() {
      let height = (this.contentHeight - 180);
      if (height < 600) {
        height = 600;
      }
      return String(height) + 'px';
    },
    disabled() {
      return this.month.saiSafetyHealthyTargetMonthStepCd === 'SSHTM00002'
        // 결재중인 경우 비활성화
        || this.month.approvalStatusCd === 'ASC0000001'
    },
    tabItems() {
      let items = [];
      if (this.month.depts && this.month.depts.length > 0) {
        this.$_.forEach(this.month.depts, dept => {
          items.push({
            name: dept.deptCd, 
            label: dept.deptName,
            class: 'tab-bluegrey', icon: 'dangerous', 
            deptCd: dept.deptCd, 
            results: dept.results, 
            completeFlag: dept.completeFlag,
            badge: dept.completeFlag === 'Y' ? '완료' : null
          })
        })
      }
      
      return items;
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.month.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.month.approvalStatusCd, 
        apprEditable: this.month.saiSafetyHealthyTargetMonthStepCd === 'SSHTM00001',
        param: this.month, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000024', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          saiSafetyHealthyTargetMonthId: this.month.saiSafetyHealthyTargetMonthId,
          isApprContent: true
        },
        approvalRequestName: `${this.popupParam.targetYear}-${this.$_.padStart(this.popupParam.month, 2, '0')} 안전보건목표 실적`, // 결재요청명 (문서 title)
        approvalConnId: this.month.saiSafetyHealthyTargetMonthId, // 결재연결용 업무일련번호 (문서 key)
      }
    }
    // [E] 결재관련 버튼 컨트롤
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.safetyHealthy.month.get.url;
      this.printUrl = selectConfig.sai.safetyHealthy.month.print.url;
      this.updateUrl = transactionConfig.sai.safetyHealthy.month.update.url;
      this.approvalUrl = transactionConfig.sai.safetyHealthy.month.update.url;
      // code setting
      this.setGridHeader()
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiSafetyHealthyTargetMonthId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          saiSafetyHealthyTargetMonthId: this.popupParam.saiSafetyHealthyTargetMonthId,
        };
        this.$http.request((_result) => {
          this.$_.extend(this.month, _result.data);
          this.tab = this.month.depts[0].deptCd
        },);
      } else {
        // 신규등록 * 해당 경우로 들어오지 않음
        this.$set(this.month, 'yearmonth', `${this.popupParam.targetYear}-${this.$_.padStart(this.popupParam.month, 2, '0')}`)
      }
    },
    setGridHeader() {
      let yearmonth = `${this.popupParam.targetYear}-${this.$_.padStart(this.popupParam.month, 2, '0')}`;
      let yearmonthDays = this.$comm.moment(yearmonth).daysInMonth();
      let childCols = [];
      let childEtcCols = [];
      for (let idx = 1; idx <= yearmonthDays; idx++) {
        let isHoliday = this.isHoliday(`${yearmonth}-${this.$_.padStart(idx, 2, '0')}`);
        childCols.push({
          name: `day${idx}`,
          field: `day${idx}`,
          label: `${idx}`,
          align: 'center',
          style: 'width:30px',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          color: 'blue', 
          month: 1,
          disableTarget: 'ehsFlag',
          disableCon: 'N',
          headerColor: isHoliday ? '#D9D9D9' : '#FFC000',
          tdColor: isHoliday ? '#D9D9D9' : null
        })
        childEtcCols.push({
          name: `day${idx}`,
          field: `day${idx}`,
          label: `${idx}`,
          align: 'center',
          style: 'width:30px',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          color: 'blue', 
          month: 1,
          headerColor: isHoliday ? '#D9D9D9' : '#FFC000',
          tdColor: isHoliday ? '#D9D9D9' : null
        })
      }
      let performanceCols = {
        name: 'performance',
        field: 'performance',
        label: `${this.popupParam.targetYear}년 ${this.popupParam.month}월`,
        child: childCols
      };
      let performanceEtcCols = {
        name: 'performance',
        field: 'performance',
        label: `${this.popupParam.targetYear}년 ${this.popupParam.month}월`,
        child: childEtcCols
      };
      this.grid.columns = [
        {
          name: 'targetName',
          field: 'targetName',
          label: '구분',
          align: 'center',
          style: 'width: 100px',
          sortable: false,
          fix: true,
        },
        {
          name: 'detailName',
          field: 'detailName',
          label: '점검내용',
          align: 'left',
          style: 'width: 200px',
          sortable: false,
          fix: true,
        },
        {
          name: 'inspectionCycle',
          field: 'inspectionCycle',
          label: '점검주기',
          align: 'center',
          style: 'width:80px',
          sortable: false,
          fix: true,
        },
        {
          name: 'managerName',
          field: 'managerName',
          label: '담당자',
          align: 'left',
          style: 'width:150px',
          sortable: false,
          fix: true,
        },
        {
          name: 'result',
          field: 'result',
          label: '점검결과',
          align: 'center',
          child: [
            {
              name: 'resultTeamLeader',
              field: 'resultTeamLeader',
              label: '해당팀장',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
            {
              name: 'resultSupportTeam',
              field: 'resultSupportTeam',
              label: '생산지원팀',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
            {
              name: 'resultManager',
              field: 'resultManager',
              label: '관리감독자',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
          ]
        },
        {
          name: 'relatedEvidence',
          field: 'relatedEvidence',
          label: '관련근거',
          align: 'left',
          style: 'width:270px',
          type: 'html',
          sortable: false
        },
      ];
      this.grid.columns.splice(4, 0, performanceCols)
      this.grid.etcColumns = [
        {
          name: 'targetName',
          field: 'targetName',
          label: '구분',
          align: 'center',
          style: 'width: 100px',
          sortable: false,
          fix: true,
        },
        {
          name: 'detailName',
          field: 'detailName',
          label: '점검내용',
          align: 'left',
          style: 'width: 200px',
          sortable: false,
          fix: true,
        },
        {
          name: 'inspectionCycle',
          field: 'inspectionCycle',
          label: '점검주기',
          align: 'center',
          style: 'width:80px',
          sortable: false,
          fix: true,
        },
        {
          name: 'managerName',
          field: 'managerName',
          label: '담당자',
          align: 'left',
          style: 'width:150px',
          sortable: false,
          fix: true,
        },
        {
          name: 'result',
          field: 'result',
          label: '점검결과',
          align: 'center',
          child: [
            {
              name: 'resultTeamLeader',
              field: 'resultTeamLeader',
              label: '해당팀장',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
            {
              name: 'resultSupportTeam',
              field: 'resultSupportTeam',
              label: '생산지원팀',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
            {
              name: 'resultManager',
              field: 'resultManager',
              label: '관리감독자',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
          ]
        },
        {
          name: 'relatedEvidence',
          field: 'relatedEvidence',
          label: '관련근거',
          align: 'left',
          style: 'width:270px',
          type: 'html',
          sortable: false
        },
      ];
      this.grid.etcColumns.splice(4, 0, performanceEtcCols)
    },
    isHoliday(day) {
      // 일 : 0
      // 토 : 6
      let dayOfWeek = this.$comm.moment(day).day();
      if ([0, 6].indexOf(dayOfWeek) > -1) {
        return true;
      } else if (this.$_.findIndex(this.popupParam.holidaies, { holiday: day }) > -1) {
        return true;
      } else {
        return false;
      }
    },
    saveTargetMonth() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.month.regUserId = this.$store.getters.user.userId
              this.month.chgUserId = this.$store.getters.user.userId
      
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveTargetMonthCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    approvalHealtyTargetMonth() {
      /**
       * 유효성 체크
       *  1. 부서 전체가 완료 한 경우
       */
      let nonCompleteDepts = this.$_.filter(this.month.depts, dept => {
        return dept.completeFlag !== 'Y'
      })
      if (nonCompleteDepts && nonCompleteDepts.length > 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: `[${this.$_.map(nonCompleteDepts, 'deptName').join(',')}] 부서에서 실적을 넣고 있는중 입니다.`, // 추진 점검내용을 하나 이상 입력하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      } 

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isApproval = !this.isApproval
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      });
    },
    approvalHealtyTargetMonthCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    printInfo() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiSafetyHealthyTargetMonthId: this.popupParam.saiSafetyHealthyTargetMonthId
      };
      this.$http.request(
        _result => {
          let fileOrgNm = '[' + this.month.yearmonth + '] 안전보건목표 실적.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>